import React from 'react';
import DOMPurify from 'dompurify';
import { Article } from '../types';

interface NewsItemProps {
  article: Article;
  animationDelay?: number;
}

const NewsItem: React.FC<NewsItemProps> = ({ article, animationDelay = 0 }) => {
  const extractTitleFromHTML = (htmlString: string): string => {
    if (!htmlString.includes('href=')) {
      return htmlString;
    }
    
    const parser = new DOMParser();
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const doc = parser.parseFromString(sanitizedHTML, 'text/html');
    
    return doc.body.textContent || '';
  };

  const extractUrlFromHTML = (htmlString: string): string => {
    if (!htmlString.includes('href=')) {
      return article.url;
    }
    
    const parser = new DOMParser();
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const doc = parser.parseFromString(sanitizedHTML, 'text/html');
    
    const anchor = doc.querySelector('a');
    return anchor ? anchor.href : article.url;
  };

  const cleanDescription = (description: string): string => {
    if (!description) return 'No description available';
    
    const parser = new DOMParser();
    const sanitizedHTML = DOMPurify.sanitize(description);
    const doc = parser.parseFromString(sanitizedHTML, 'text/html');
    
    return doc.body.textContent || 'No description available';
  };

  const title = extractTitleFromHTML(article.title);
  const url = extractUrlFromHTML(article.title);
  const description = cleanDescription(article.description);

  return (
    <div 
      className="card p-3" 
      style={{ 
        animationDelay: `${animationDelay}ms`,
        animationFillMode: 'backwards'
      }}
    >
      <h5><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></h5>
      <p>{description}</p>
      
      <div className="d-flex justify-content-between">
        <a href={url} target="_blank" rel="noopener noreferrer"className="btn btn-primary">Read More</a>
        <span className="news-date text-muted small mt-3">{new Date(article.publishedAt).toLocaleDateString()}</span>
      </div>
    </div>
  );
};

export default NewsItem;