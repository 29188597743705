import React, { useState, useEffect, useRef } from 'react';
import { Language } from '../types';
import { getUserLocation, getLanguageFromCountry } from '../services/locationService';

interface LanguageSelectorProps {
  onLanguageChange: (language: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ onLanguageChange }) => {
  // const [languages] = useState<Language[]>([
  //   { code: 'ar', name: 'العربية' },
  //   { code: 'bn', name: 'বাংলা' },
  //   { code: 'zh', name: '中文' },
  //   { code: 'nl', name: 'Nederlands' },
  //   { code: 'en', name: 'English' },
  //   { code: 'fi', name: 'Suomi' },
  //   { code: 'fr', name: 'Français' },
  //   { code: 'de', name: 'Deutsch' },
  //   { code: 'hi', name: 'हिन्दी' },
  //   { code: 'it', name: 'Italiano' },
  //   { code: 'ja', name: '日本語' },
  //   { code: 'ko', name: '한국어' },
  //   { code: 'lt', name: 'Lietuvių' },
  //   { code: 'no', name: 'Norsk' },
  //   { code: 'pl', name: 'Polski' },
  //   { code: 'pt', name: 'Português' },
  //   { code: 'ru', name: 'Русский' },
  //   { code: 'es', name: 'Español' },
  //   { code: 'sv', name: 'Svenska' },
  //   { code: 'th', name: 'ไทย' },
  //   { code: 'tr', name: 'Türkçe' },
  //   { code: 'uk', name: 'Українська' },
  //   { code: 'vi', name: 'Tiếng Việt' }
  // ]);
  const [languages] = useState<Language[]>(
    [
      {"name": "Abkhazian", "code": "ab"},
      {"name": "Afar", "code": "aa"},
      {"name": "Afrikaans", "code": "af"},
      {"name": "Akan", "code": "ak"},
      {"name": "Albanian", "code": "sq"},
      {"name": "Amharic", "code": "am"},
      {"name": "Arabic", "code": "ar"},
      {"name": "Aragonese", "code": "an"},
      {"name": "Armenian", "code": "hy"},
      {"name": "Assamese", "code": "as"},
      {"name": "Aymara", "code": "ay"},
      {"name": "Azerbaijani", "code": "az"},
      {"name": "Bashkir", "code": "ba"},
      {"name": "Basque", "code": "eu"},
      {"name": "Belarusian", "code": "be"},
      {"name": "Bengali", "code": "bn"},
      {"name": "Bihari", "code": "bh"},
      {"name": "Bislama", "code": "bi"},
      {"name": "Bosnian", "code": "bs"},
      {"name": "Breton", "code": "br"},
      {"name": "Bulgarian", "code": "bg"},
      {"name": "Burmese", "code": "my"},
      {"name": "Catalan", "code": "ca"},
      {"name": "Chamorro", "code": "ch"},
      {"name": "Chechen", "code": "ce"},
      {"name": "Chinese", "code": "zh"},
      {"name": "Chuvash", "code": "cv"},
      {"name": "Cornish", "code": "kw"},
      {"name": "Corsican", "code": "co"},
      {"name": "Croatian", "code": "hr"},
      {"name": "Czech", "code": "cs"},
      {"name": "Danish", "code": "da"},
      {"name": "Dutch", "code": "nl"},
      {"name": "Dzongkha", "code": "dz"},
      {"name": "English", "code": "en"},
      {"name": "Esperanto", "code": "eo"},
      {"name": "Estonian", "code": "et"},
      {"name": "Ewe", "code": "ee"},
      {"name": "Fijian", "code": "fj"},
      {"name": "Finnish", "code": "fi"},
      {"name": "French", "code": "fr"},
      {"name": "Fulah", "code": "ff"},
      {"name": "Galician", "code": "gl"},
      {"name": "Georgian", "code": "ka"},
      {"name": "German", "code": "de"},
      {"name": "Greek", "code": "el"},
      {"name": "Guaraní", "code": "gn"},
      {"name": "Gujarati", "code": "gu"},
      {"name": "Haitian Creole", "code": "ht"},
      {"name": "Hausa", "code": "ha"},
      {"name": "Hebrew", "code": "he"},
      {"name": "Hindi", "code": "hi"},
      {"name": "Hmong", "code": "hm"},
      {"name": "Hungarian", "code": "hu"},
      {"name": "Icelandic", "code": "is"},
      {"name": "Igbo", "code": "ig"},
      {"name": "Indonesian", "code": "id"},
      {"name": "Interlingua", "code": "ia"},
      {"name": "Irish", "code": "ga"},
      {"name": "Italian", "code": "it"},
      {"name": "Japanese", "code": "ja"},
      {"name": "Javanese", "code": "jw"},
      {"name": "Kannada", "code": "kn"},
      {"name": "Kazakh", "code": "kk"},
      {"name": "Khmer", "code": "km"},
      {"name": "Kinyarwanda", "code": "rw"},
      {"name": "Korean", "code": "ko"},
      {"name": "Kurdish", "code": "ku"},
      {"name": "Kyrgyz", "code": "ky"},
      {"name": "Lao", "code": "lo"},
      {"name": "Latin", "code": "la"},
      {"name": "Latvian", "code": "lv"},
      {"name": "Lithuanian", "code": "lt"},
      {"name": "Luxembourgish", "code": "lb"},
      {"name": "Macedonian", "code": "mk"},
      {"name": "Malagasy", "code": "mg"},
      {"name": "Malay", "code": "ms"},
      {"name": "Malayalam", "code": "ml"},
      {"name": "Maltese", "code": "mt"},
      {"name": "Maori", "code": "mi"},
      {"name": "Marathi", "code": "mr"},
      {"name": "Mongolian", "code": "mn"},
      {"name": "Nepali", "code": "ne"},
      {"name": "Norwegian", "code": "no"},
      {"name": "Nyanja", "code": "ny"},
      {"name": "Odia", "code": "or"},
      {"name": "Pashto", "code": "ps"},
      {"name": "Persian", "code": "fa"},
      {"name": "Polish", "code": "pl"},
      {"name": "Portuguese", "code": "pt"},
      {"name": "Punjabi", "code": "pa"},
      {"name": "Quechua", "code": "qu"},
      {"name": "Romanian", "code": "ro"},
      {"name": "Russian", "code": "ru"},
      {"name": "Samoan", "code": "sm"},
      {"name": "Scots Gaelic", "code": "gd"},
      {"name": "Serbian", "code": "sr"},
      {"name": "Sesotho", "code": "st"},
      {"name": "Shona", "code": "sn"},
      {"name": "Sindhi", "code": "sd"},
      {"name": "Sinhalese", "code": "si"},
      {"name": "Slovak", "code": "sk"},
      {"name": "Slovenian", "code": "sl"},
      {"name": "Somali", "code": "so"},
      {"name": "Spanish", "code": "es"},
      {"name": "Sundanese", "code": "su"},
      {"name": "Swahili", "code": "sw"},
      {"name": "Swedish", "code": "sv"},
      {"name": "Tajik", "code": "tg"},
      {"name": "Tamil", "code": "ta"},
      {"name": "Tatar", "code": "tt"},
      {"name": "Telugu", "code": "te"},
      {"name": "Thai", "code": "th"},
      {"name": "Turkish", "code": "tr"},
      {"name": "Turkmen", "code": "tk"},
      {"name": "Twi", "code": "tw"},
      {"name": "Ukrainian", "code": "uk"},
      {"name": "Urdu", "code": "ur"},
      {"name": "Uzbek", "code": "uz"},
      {"name": "Vietnamese", "code": "vi"},
      {"name": "Welsh", "code": "cy"},
      {"name": "Xhosa", "code": "xh"},
      {"name": "Yiddish", "code": "yi"},
      {"name": "Yoruba", "code": "yo"},
      {"name": "Zulu", "code": "zu"}
    ]
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [isChangingLanguage, setIsChangingLanguage] = useState<boolean>(false);
  const languageDebounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    async function detectUserLanguage() {
      try {
        const location = await getUserLocation();
        const languageCode = getLanguageFromCountry(location.country_code);
        if (languageCode) {
          setSelectedLanguage(languageCode);
          onLanguageChange(languageCode);
        }
      } catch (error) {
        console.error('Error detecting user language:', error);
      }
    }

    detectUserLanguage();
    
    return () => {
      if (languageDebounceTimerRef.current) {
        clearTimeout(languageDebounceTimerRef.current);
      }
    };
  }, [onLanguageChange]);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = e.target.value;
    
    if (isChangingLanguage) {
      return;
    }
    
    setIsChangingLanguage(true);
    setSelectedLanguage(newLanguage);
    
    if (languageDebounceTimerRef.current) {
      clearTimeout(languageDebounceTimerRef.current);
    }
    
    languageDebounceTimerRef.current = setTimeout(() => {
      onLanguageChange(newLanguage);
      setIsChangingLanguage(false);
    }, 500);
  };

  return (
    <div className="language-selector">
      <select 
        value={selectedLanguage} 
        onChange={handleLanguageChange}
        disabled={isChangingLanguage}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
      {isChangingLanguage && (
        <div className="language-loading-indicator" style={{ 
          fontSize: '0.8rem', 
          marginTop: '5px', 
          color: '#666' 
        }}>
          Changing language...
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;